<template>
    <div class="login d-flex justify-center align-center">
        <div class="login__container">
            <div class="login__logo d-flex">
                <img height="80px" src="/imgs/logo.png" />
            </div>
            <div class="login__form">
                <h1 class="login__text text-center">Nazaret</h1>
                <v-form
                    v-model="valid"
                    v-on:submit.prevent="eSendFormUser()"
                    lazy-validation
                    ref="formlogin"
                >
                    <MTextFieldComp
                        class="mt-5 mb-2"
                        v-model="datesUser.username"
                        :clearable="true"
                        label="Usuario"
                        :dense="true"
                        :counter="20"
                        @click="eSendFormUser()"
                        :rules="[
                            v => !this.mRuleEmptyField(v) || 'El dato es requerido',
                            v => (v+'').length<=20 || 'El username no debe exeder los 20 dígitos'
                        ]"
                    />
                    <MTextFieldComp
                        v-model="datesUser.password"
                        :clearable="true"
                        label="Contraseña"
                        :dense="true"
                        :type="showPassword ? 'text' : 'password'"
                        :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @clicksee="showPassword = !showPassword"
                        @click="eSendFormUser()"
                        :rules="[v => !this.mRuleEmptyField(v) || 'El dato es requerido']"
                    />
                </v-form>
                <div class="text-center">
                    <MBtnNormalComp
                        color="primary"
                        @click="eSendFormUser()"
                        :loading="loadingBtn"
                    >INGRESAR</MBtnNormalComp>
                </div>
            </div>
        </div>
        <OSmsErrorComp
            @click="dialogError=false"
            :value="dialogError"
            :smsError="smsError"
            :actionError="actionError"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MBtnNormalComp
} from "../../components/molecules";
import { OSmsErrorComp } from "../../components/organisms";
import {
    rulesMixin,
    responseServerMixin,
    userMixin
} from "../../mixins";
import { User } from "../../models";
import { mapMutations } from "vuex";
export default {
    mixins: [
        rulesMixin,
        responseServerMixin,
        userMixin
    ],
    components: {
        MTextFieldComp,
        MBtnNormalComp,
        OSmsErrorComp
    },
    data: () => ({
        dialogError: false,
        smsError: [],
        actionError: false,
        datesUser: new User(),
        showPassword: false,
        valid: true,
        loadingBtn: false,
        mobile: false
    }),
    methods: {
        ...mapMutations('userStore', ['saveHeader']),
        rulesDates() {
            if (!this.$refs.formlogin.validate()) return false;
            return true;
        },
        processError(error) {
            if (error.response !==  undefined ) {
                console.log(error);
                if (error.response.status === 402)
                    this.smsError = ["Su cuenta se encuentra inhabilitada, porfavor comuniquese con su superior"];
                if (error.response.status === 401)
                    this.smsError = ["El username o la contraseña están incorrectos, porfavor revise sus datos"];
                if (error.response.status === 400)
                    this.smsError = error.response.data;
                this.actionError = true;
            } else {
                this.smsError = ['Revise su conección de internet y refresque la página porfavor'];
                this.actionError = false;
            }
            this.dialogError = true;
        },
        async loginUser(parameters) {
            try {
                const response = await User.login(parameters);
                localStorage.setItem('nazaret', JSON.stringify(response))
                var header = {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + response.token,
                }
                this.saveHeader(header);
                this.loadingBtn = false;
                if (!this.mobile)
                    this.$router.replace('/');
                else this.$router.replace('/sale-mobile');
            } catch(error) {
                this.loadingBtn = false;
                this.processError(error);
            }
        },
        eSendFormUser() {
            if (this.rulesDates()) {
                var md5 = require('md5');
                this.loadingBtn = true;
                if (JSON.parse(localStorage.getItem("device")))
                    var parameters = {
                        'username': this.datesUser.username,
                        'password': md5(this.datesUser.password),
                        'id_cash_register': JSON.parse(localStorage.getItem("device")).id_cash_register
                    }
                else
                    var parameters = {
                        'username': this.datesUser.username,
                        'password': md5(this.datesUser.password)
                    }
                this.loginUser(parameters);
            } else {
                this.smsError = ["Corrija los datos marcados con rojo y vuelva a intentarlo porfavor"];
                this.actionError = true;
                this.dialogError = true;
            }
        },
        handleKeyUp (event) {
            if (!this.mobile) {
                if (this.dialogError) {
                    if (event.keyCode == 65)
                        this.dialogError = false;
                }
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true;
        window.addEventListener('keyup', this.handleKeyUp);
        if (JSON.parse(localStorage.getItem('nazaret')))
            this.$router.replace('/');
    }
}
</script>
